<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">行事曆管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" /> 匯出 CSV
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span>
            </div>
          </div>
        </div>
        <button v-show="!grid.isEditing && canCreate" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="grid grid-cols-12 gap-5 mt-5">
      <div class="box col-span-12 xl:col-span-12 p-4 intro-y">
        <div class="">
          <FullCalendar ref="fullCalendar" :options="calendarOptions" />
        </div>
      </div>
      <div class="box col-span-12 xl:col-span-12 p-4 intro-y">
        <!-- <div class=" xl:hidden flex items-center mb-3">
          <div class="">當日訂單：</div>
          <Litepicker class="input w-56 border block" />
        </div> -->
        <div class="">
          <div class="text-xl font-bold">
            {{ selectDate }}行事曆
          </div>
          <!-- <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
            <div class="xl:flex sm:mr-auto">
              <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
                <input type="search" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword" placeholder="請輸入部門" />
              </div>
              <div class="mt-2 xl:mt-0">
                <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
                  搜尋
                </button>
              </div>
            </div>
            <div class="flex mt-2 sm:mt-0">
              <button class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300" @click="grid.onPrint({ mode: 'curret' })">
                <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
              </button>
              <div class="dropdown w-1/2 sm:w-auto ml-2">
                <button class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300">
                  <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
                  <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                </button>
                <div class="dropdown-box">
                  <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                    <a
                      href="javascript:;"
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      @click="grid.onExport({ type: 'csv' })"
                    >
                      <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                    </a>
                    <a
                      href="javascript:;"
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      @click="grid.onExport({ type: 'html' })"
                    >
                      <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="overflow-x-auto scrollbar-hidden mt-2">
            <grid
              ref="grid"
              v-bind="gridOptions"
              @addNewRow="initData"
              @removeSelectedRows="hideBatchDropDown()"
              @modalResize="onGridModalResize"
            >
              <template #modal="{ row, submit, reset }">
                <vxe-form
                  ref="form"
                  v-bind="formOptions"
                  :data="row"
                  @reset="reset"
                  @submit="submit"
                >
                </vxe-form>
              </template>
              <template #modal-footer="{ row }">
                <vxe-button v-if="row.Type == 2 && row.Id" type="button" status="primary" content="關閉" @click="() => { grid.isModalPopup = false; grid.editingRow = null; }"></vxe-button>
                <vxe-button v-if="row.Type != 2 || !row.Id" type="submit" status="primary" content="確認" @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
                <vxe-button v-if="row.Type != 2 || !row.Id" type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
              </template>
              <template #filter-name="{ column, $panel }">
                <div v-for="(option, index) in column.filters" :key="index" class="flex item-center justify-center">
                  <input type="checkbox"
                    class="input input--switch border mt-4 ml-2"
                    :checked="option.checked"
                    @input="grid.changeFilterEvent(event, option, $panel, true)"
                  />
                  <div class="relative m-2">
                    <div class="absolute rounded-l-md w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                      {{option.label}}
                    </div>
                    <input
                      v-model.trim="option.data"
                      :disabled="!option.checked"
                      type="text"
                      class="input pl-12 w-full border col-span-4"
                      placeholder="請輸入文字"
                      @input="grid.changeFilterEvent(event, option, $panel)"
                    />
                  </div>
                </div>
              </template>
              <!-- <template #operate="{ row }">
                <button v-if="row.Type == 2"
                  class="button rounded-full mr-1 bg-blue-600 text-white" title="查看" @click.stop="grid.edit(row)">
                  <FontAwesome icon="book-open" class="w-4 h-4" />
                </button>
                <div v-else class="flex justify-center">
                  <button class="button rounded-full mr-1 bg-blue-600 text-white" title="編輯" @click.stop="grid.edit(row)">
                    <FontAwesome icon="highlighter" class="w-4 h-4" />
                  </button>
                  <button type="button" class="button rounded-full bg-red-600 text-white" title="刪除"
                    @click.stop="grid.remove(row)">
                    <FontAwesome icon="trash" class="w-4 h-4" />
                  </button>
                </div>
              </template> -->
            </grid>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
/* eslint-disable */

import 'vue-loading-overlay/dist/vue-loading.css';
import router from "@/router";
import CloudFun, { defineComponent, ref, Operator, Condition, onMounted, reactive, provide } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
// import '@fullcalendar/core/vdom'; // solves problem with Vite
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { update } from 'lodash';

interface FilterParam {
  StartTime?: string;
  EndTime?: string;
}

export default defineComponent({
  components: {
    Grid,
    FullCalendar
  },
  setup() {
    if (!CloudFun.user.Id) router.push('/login');
    
    var load = reactive({
      isLoading: true,
      fullPage: false,
    });
    const importantNotesRef = ref<HTMLElement>();

    provide("bind[importantNotesRef]", (el: HTMLElement) => {
      importantNotesRef.value = el;
    });

    const prevImportantNotes = () => {
      const el: any = importantNotesRef.value;
      el.tns.goTo("prev");
    };
    const nextImportantNotes = () => {
      const el: any = importantNotesRef.value;
      el.tns.goTo("next");
    };

    const model = CloudFun.current?.model
    const selectDate = ref('');
    const grid = ref<any>({})
    const fullCalendar = ref<any>();
    const today = new Date();
    const filters = ref({
      StartTime: formatDate(today, "yyyy/MM/dd"),
      //EndTime: formatDate(today, "yyyy/MM/dd 23:59:59"),
    } as FilterParam);

    
    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;

    const checkPermissions = (key: string) => {
      // console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canCreate = checkPermissions("CalendarEventCreate");
    const canUpdate = checkPermissions("CalendarEventUpdate");
    const canDelete = checkPermissions("CalendarEventDelete");

    const queryToCondition = (condition: Condition, query: FilterParam) => {
      if (query.StartTime) condition.and("StartTime", Operator.Equal, query.StartTime); //condition.and("StartTime", Operator.GreaterThanOrEqual, query.StartTime);
      if (query.EndTime) condition.and("EndTime", Operator.LessThanOrEqual, query.EndTime);
    }

    const calendarOptions = {
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      locale: 'zh-tw',
      height: 560,
      events: async(info: any, successCallback: any) => {
        const condition = new Condition();
        //queryToCondition(condition, { StartTime: formatDate(info.start, "yyyy/MM/dd"), EndTime: formatDate(info.end, "yyyy/MM/dd 23:59:59") })
        condition.and("StartTime", Operator.GreaterThanOrEqual, formatDate(info.start, "yyyy/MM/dd"));
        
        await model?.dispatch("calendarEvent/query", { condition }).then((data: any) => {
          const dates: { [k: string]: any } = [];
          data.forEach((e: any) => {
            var name = e.Type >= 0 ? Object.values(model.enums.CalendarEventType).find(i => i.Value == e.Type)?.Name : "日程";
            const time = formatDate(e.StartTime, "yyyy-MM-dd");
            if (!dates[time]) {
              dates[time] = {
                title: e.Subject,
                // daysOfWeek: [e.DayOfWeek],
                start: time,
                // icon: "iconsminds-reload",
                // end: "2022-12-17",
                // display: "background",
                // content: "",
                // eventType: 8,
                color: "rgba(0, 127, 255, 0.6)",
              }
            }
          })
          successCallback(Object.values(dates));
        });
      },
      dateClick(e: any) {
        selectDate.value = e.dateStr;
        //console.log(e)
        filters.value.StartTime = formatDate(e.dateStr, "yyyy/MM/dd");
        // filters.value.EndTime = formatDate(e.dateStr, "yyyy/MM/dd 23:59:59");
        grid.value.refresh();
      },
      // eventClick(info: any) {
      //   console.log(info)
      //   selectDate.value = info.dateStr;
      //   filters.value.StartTime = formatDate(info.event.startStr, "yyyy/MM/dd");
      //   filters.value.EndTime = formatDate(info.event.startStr, "yyyy/MM/dd 23:59:59");
      //   grid.value.refresh();
      // },
      // dateClick: (e: any) => {
      //   // console.log(e.dateStr);
      //   selectDate.value = e.dateStr;
      //   grid.value.refresh();
      // },
      // events: [
      //   {
      //     title: '日程',
      //     date: '2023-01-20',
      //   },
      //   {
      //     title: '日程',
      //     date: '2023-01-14',
      //   },
      //   {
      //     title: '日程',
      //     date: '2023-01-11',
      //   }
      // ]
    }
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const gridOptions: GridOptions = {
      stripe: false,
      title: '行事曆',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canCreate: canCreate,
      canUpdate: canUpdate,
      canDelete: canDelete,
      multiselect: false,
      printConfig: { sheetName: '行事曆', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '行事曆', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        {
          field: 'Subject',
          title: '主旨',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          treeNode: false,
        },
        {
          field: 'Location',
          title: '地點',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          treeNode: false,
        },
        { field: 'StartTime', title: '活動開始時間', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : '' },
        { field: 'EndTime', title: '活動結束時間', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : '' },
        { field: "Type", title: "類型", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.CalendarEventType).find(e => e.Value === cellValue)?.Name : undefined },
      ],
      promises: {
        query: model ? async (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          queryToCondition(params.condition!, filters.value);
          // console.log(params)
          //refresh calendar
          let calendarApi = fullCalendar.value.getApi();
          await calendarApi.refetchEvents();
          const result = await model.dispatch('calendarEvent/query', params);
          return result;
        } : undefined,
        queryAll: model ? () => model.dispatch('calendarEvent/query') : undefined,
        save: model ? (params) => model.dispatch('calendarEvent/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'Subject',
          title: '主旨',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Location',
          title: '地點',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Type',
          title: '類型',
          span: 12,
          itemRender: { name: "$select", props: {}, options: model ? Object.values(model.enums.CalendarEventType).map((e: any) => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'AllDay',
          title: '全日事件',
          span: 12,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        {
          field: 'StartTime',
          title: '開始時間',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'EndTime',
          title: '結束時間',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'ExpectedAttendence',
          title: '應到人數',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
        {
          field: 'Attendence',
          title: '實到人數',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
        { 
          field: 'Description',
          title: '說明',
          span: 24,
          itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '請輸入文字' } } },
      ],
      rules: {
        Subject: [{ type: 'string', required: true }],
        StartTime: [{ required: true }],
        EndTime: [{ required: true }],
        Type: [{ required: true }],
      }
    }

    onMounted(async () => {
      if(CloudFun.user.Id) {
        load.isLoading = false
      }
      const date = new Date();
      selectDate.value = formatDate(date, "yyyy-MM-dd")
      const todayBtn = document.getElementsByClassName('fc-today-button')[0]; //today button query
      todayBtn?.addEventListener('click', async function () {
        filters.value.StartTime = formatDate(date, "yyyy/MM/dd");
        // filters.value.EndTime = formatDate(date, "yyyy/MM/dd 23:59:59");
        selectDate.value = formatDate(date, "yyyy-MM-dd")
        await grid.value.refresh();
      });
    })

    const initData = (row: any, callback: any) => {
      row.AllDay = false;
      row.Attendence = 0;
      row.ExpectedAttendence = 0;
      callback();
    }



    return {
      prevImportantNotes,
      nextImportantNotes,
      load,
      grid,
      gridOptions,
      formOptions,
      fullCalendar,
      calendarOptions,
      selectDate,
      initData,
      canCreate,      
    };
  },
});
</script>
