
/* eslint-disable */

import 'vue-loading-overlay/dist/vue-loading.css';
import router from "@/router";
import CloudFun, { defineComponent, ref, Operator, Condition, onMounted, reactive, provide } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
// import '@fullcalendar/core/vdom'; // solves problem with Vite
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { update } from 'lodash';

interface FilterParam {
  StartTime?: string;
  EndTime?: string;
}

export default defineComponent({
  components: {
    Grid,
    FullCalendar
  },
  setup() {
    if (!CloudFun.user.Id) router.push('/login');
    
    var load = reactive({
      isLoading: true,
      fullPage: false,
    });
    const importantNotesRef = ref<HTMLElement>();

    provide("bind[importantNotesRef]", (el: HTMLElement) => {
      importantNotesRef.value = el;
    });

    const prevImportantNotes = () => {
      const el: any = importantNotesRef.value;
      el.tns.goTo("prev");
    };
    const nextImportantNotes = () => {
      const el: any = importantNotesRef.value;
      el.tns.goTo("next");
    };

    const model = CloudFun.current?.model
    const selectDate = ref('');
    const grid = ref<any>({})
    const fullCalendar = ref<any>();
    const today = new Date();
    const filters = ref({
      StartTime: formatDate(today, "yyyy/MM/dd"),
      //EndTime: formatDate(today, "yyyy/MM/dd 23:59:59"),
    } as FilterParam);

    
    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;

    const checkPermissions = (key: string) => {
      // console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canCreate = checkPermissions("CalendarEventCreate");
    const canUpdate = checkPermissions("CalendarEventUpdate");
    const canDelete = checkPermissions("CalendarEventDelete");

    const queryToCondition = (condition: Condition, query: FilterParam) => {
      if (query.StartTime) condition.and("StartTime", Operator.Equal, query.StartTime); //condition.and("StartTime", Operator.GreaterThanOrEqual, query.StartTime);
      if (query.EndTime) condition.and("EndTime", Operator.LessThanOrEqual, query.EndTime);
    }

    const calendarOptions = {
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      locale: 'zh-tw',
      height: 560,
      events: async(info: any, successCallback: any) => {
        const condition = new Condition();
        //queryToCondition(condition, { StartTime: formatDate(info.start, "yyyy/MM/dd"), EndTime: formatDate(info.end, "yyyy/MM/dd 23:59:59") })
        condition.and("StartTime", Operator.GreaterThanOrEqual, formatDate(info.start, "yyyy/MM/dd"));
        
        await model?.dispatch("calendarEvent/query", { condition }).then((data: any) => {
          const dates: { [k: string]: any } = [];
          data.forEach((e: any) => {
            var name = e.Type >= 0 ? Object.values(model.enums.CalendarEventType).find(i => i.Value == e.Type)?.Name : "日程";
            const time = formatDate(e.StartTime, "yyyy-MM-dd");
            if (!dates[time]) {
              dates[time] = {
                title: e.Subject,
                // daysOfWeek: [e.DayOfWeek],
                start: time,
                // icon: "iconsminds-reload",
                // end: "2022-12-17",
                // display: "background",
                // content: "",
                // eventType: 8,
                color: "rgba(0, 127, 255, 0.6)",
              }
            }
          })
          successCallback(Object.values(dates));
        });
      },
      dateClick(e: any) {
        selectDate.value = e.dateStr;
        //console.log(e)
        filters.value.StartTime = formatDate(e.dateStr, "yyyy/MM/dd");
        // filters.value.EndTime = formatDate(e.dateStr, "yyyy/MM/dd 23:59:59");
        grid.value.refresh();
      },
      // eventClick(info: any) {
      //   console.log(info)
      //   selectDate.value = info.dateStr;
      //   filters.value.StartTime = formatDate(info.event.startStr, "yyyy/MM/dd");
      //   filters.value.EndTime = formatDate(info.event.startStr, "yyyy/MM/dd 23:59:59");
      //   grid.value.refresh();
      // },
      // dateClick: (e: any) => {
      //   // console.log(e.dateStr);
      //   selectDate.value = e.dateStr;
      //   grid.value.refresh();
      // },
      // events: [
      //   {
      //     title: '日程',
      //     date: '2023-01-20',
      //   },
      //   {
      //     title: '日程',
      //     date: '2023-01-14',
      //   },
      //   {
      //     title: '日程',
      //     date: '2023-01-11',
      //   }
      // ]
    }
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const gridOptions: GridOptions = {
      stripe: false,
      title: '行事曆',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canCreate: canCreate,
      canUpdate: canUpdate,
      canDelete: canDelete,
      multiselect: false,
      printConfig: { sheetName: '行事曆', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '行事曆', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        {
          field: 'Subject',
          title: '主旨',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          treeNode: false,
        },
        {
          field: 'Location',
          title: '地點',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          treeNode: false,
        },
        { field: 'StartTime', title: '活動開始時間', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : '' },
        { field: 'EndTime', title: '活動結束時間', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : '' },
        { field: "Type", title: "類型", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.CalendarEventType).find(e => e.Value === cellValue)?.Name : undefined },
      ],
      promises: {
        query: model ? async (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          queryToCondition(params.condition!, filters.value);
          // console.log(params)
          //refresh calendar
          let calendarApi = fullCalendar.value.getApi();
          await calendarApi.refetchEvents();
          const result = await model.dispatch('calendarEvent/query', params);
          return result;
        } : undefined,
        queryAll: model ? () => model.dispatch('calendarEvent/query') : undefined,
        save: model ? (params) => model.dispatch('calendarEvent/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'Subject',
          title: '主旨',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Location',
          title: '地點',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Type',
          title: '類型',
          span: 12,
          itemRender: { name: "$select", props: {}, options: model ? Object.values(model.enums.CalendarEventType).map((e: any) => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'AllDay',
          title: '全日事件',
          span: 12,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        {
          field: 'StartTime',
          title: '開始時間',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'EndTime',
          title: '結束時間',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        {
          field: 'ExpectedAttendence',
          title: '應到人數',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
        {
          field: 'Attendence',
          title: '實到人數',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
        { 
          field: 'Description',
          title: '說明',
          span: 24,
          itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '請輸入文字' } } },
      ],
      rules: {
        Subject: [{ type: 'string', required: true }],
        StartTime: [{ required: true }],
        EndTime: [{ required: true }],
        Type: [{ required: true }],
      }
    }

    onMounted(async () => {
      if(CloudFun.user.Id) {
        load.isLoading = false
      }
      const date = new Date();
      selectDate.value = formatDate(date, "yyyy-MM-dd")
      const todayBtn = document.getElementsByClassName('fc-today-button')[0]; //today button query
      todayBtn?.addEventListener('click', async function () {
        filters.value.StartTime = formatDate(date, "yyyy/MM/dd");
        // filters.value.EndTime = formatDate(date, "yyyy/MM/dd 23:59:59");
        selectDate.value = formatDate(date, "yyyy-MM-dd")
        await grid.value.refresh();
      });
    })

    const initData = (row: any, callback: any) => {
      row.AllDay = false;
      row.Attendence = 0;
      row.ExpectedAttendence = 0;
      callback();
    }



    return {
      prevImportantNotes,
      nextImportantNotes,
      load,
      grid,
      gridOptions,
      formOptions,
      fullCalendar,
      calendarOptions,
      selectDate,
      initData,
      canCreate,      
    };
  },
});
